  <template>
  <validation-observer ref="create-job">
    <b-form
      class="p-2"
      @submit.prevent
    >
      <b-row>
        <b-col
          sm="12"
          md="6"
        >
          <b-row>
            <b-col sm="12">
              <validation-provider
                v-slot="validationContext"
                name="Nombre"
                rules="required"
              >
                <b-form-group
                  label="Nombre"
                  label-for="job-name"
                >
                  <b-form-input
                    id="job-name"
                    :value="model.name"
                    placeholder="Nombre"
                    :state="$getValidationState(validationContext)"
                    @input="update('name', $event)"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Project manager"
                label-for="job-user"
              >
                <BaseSelect
                  id="job-user"
                  :value="model.user"
                  :resource="$data.$constants.RESOURCES.RESOURCE_USERS"
                  @input="update('user', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col lg="6">
              <validation-provider
                v-slot="validationContext"
                name="División"
                rules="required"
              >
                <b-form-group
                  label="División"
                  label-for="job-division"
                >
                  <BaseSelect
                    id="job-division"
                    :value="model.division"
                    :resource="$data.$constants.RESOURCES.RESOURCE_DIVISIONS"
                    http-method="get"
                    :disabled="!divisionSelectEnabled"
                    @input="update('division', $event)"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col lg="6">
              <validation-provider
                v-slot="validationContext"
                name="Fecha"
                rules="required"
              >
                <b-form-group
                  label="Fecha"
                  label-for="job-date"
                >
                  <b-form-datepicker
                    id="job-date"
                    :value="model.job_date"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    size="sm"
                    reset-button
                    :hide-header="true"
                    placeholder="Fecha"
                    :label-help="null"
                    :state="$getValidationState(validationContext)"
                    @input="update('job_date', $event)"
                    start-weekday="1"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="offerSelectVisible"
              sm="12"
            >
              <b-form-group
                label="Oferta"
                label-for="job-offer"
              >
                <BaseSelect
                  id="job-offer"
                  :value="model.offer"
                  :resource="$data.$constants.RESOURCES.RESOURCE_OFFERS"
                  :http-body="{ status_id: offerStatusAcceptedId }"
                  @input="update('offer', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <validation-provider
                v-slot="validationContext"
                name="Cliente"
                rules="required"
              >
                <b-form-group
                  label="Cliente"
                  label-for="job-client"
                >
                  <ClientSelect
                    id="job-client"
                    :value="model.client"                    
                    @input="update('client', $event)"
                    @selected="handleClientSelect"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="12">
              <validation-provider
                v-slot="validationContext"
                name="Obra"
                rules="required"
              >
                <b-form-group
                  label="Obra"
                  label-for="job-project"
                >
                  <ProjectSelect
                    id="job-project"
                    :value="model.project"                    
                    @input="update('project', $event)"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col v-if="$route.name !== 'createJob'" sm="12">
              <b-form-group
                label="Contactos"
                label-for="job-contacts"
              >
                <ContactSelected
                  :key="selectedContactKey"
                  :value="model.contacts"
                  :disabled="!clientId"
                  :httpBody="{ client_id: clientId }"
                  :clientId="clientId"
                  @input="update('contacts', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12" v-if="model.division && !isMaintenance">
              <b-form-group
                label="Referencia cliente"
                label-for="ref-client"
              >
                <b-form-input
                  id="ref-client"
                  :value="model.ref_client"
                  placeholder="Referencia cliente"
                  @input="update('ref_client', $event)"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          sm="12"
          md="6"
        >
          <b-row>
            <b-col sm="12">
              <validation-provider
                  v-slot="validationContext"
                  name="Estado"
                  rules="required"
              >
              <b-form-group
                label="Estado"
                label-for="offer-status"
              >
                <StatusSelect
                  :value="model.status"
                  :type="statusType"
                  @input="update('status', $event)"
                />
                <small class="text-danger">{{ validationContext.errors[0] }}</small>
              </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="12">
              <b-form-checkbox
                :checked="model.signed_contract"
                :value="1"
                :unchecked-value="0"
                class="my-2"
                @input="update('signed_contract', $event)"
              >
                Contrato firmado
              </b-form-checkbox>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Contrato"
                label-for="job-contract"
              >
                <FileDropZone
                  id="job-contract"
                  ref="contract-input"
                  :files="files"
                  :max-files="1"
                  @removed-file="removedContract = $event"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Observaciones"
                label-for="job-observations"
              >
                <b-form-textarea
                  :value="model.observations"
                  name="job-observations"
                  placeholder="Observaciones"
                  rows="4"
                  max-rows="4"
                  @input="update('observations', $event)"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import ApiRestService from '@/api/base-api'
import FormMixin from '@/mixins/formMixin'
import { JOBS_STATUS_TYPE } from '@/api/status-api'
import BaseSelect from '@/components/ui/select/BaseSelect.vue'
import ClientSelect from '@/components/clients/select/ClientSelect.vue'
import ProjectSelect from '@/components/projects/select/ProjectSelect.vue'
import ContactSelected from "@/components/contacts/select/ContactSelect.vue"
import StatusSelect from '@/components/status/select/StatusSelect.vue'
import FileDropZone from '@/components/ui/file/FileDropZone.vue'
import { mapGetters } from 'vuex'

const OFFER_STATUS_ACCEPTED_ID = 9

export default {
  name: 'JobProfileForm',
  components: {
    FileDropZone,
    StatusSelect,
    ProjectSelect,
    ClientSelect,
    BaseSelect,
    ContactSelected
  },
  mixins: [FormMixin],
  props: {
    divisionSelectEnabled: {
      type: Boolean,
      default: true,
    },
    offerSelectVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      statusType: JOBS_STATUS_TYPE,
      removedContract: null,
      offerStatusAcceptedId: OFFER_STATUS_ACCEPTED_ID,
      selectedContactIsDisabled: true,
      selectedContactKey: null
    }
  },
  computed: {
    files() {
      return this.model?.contract || []
    },
    clientId () {
      return this.model?.client?.id
    },
    isMaintenance() {
      return this.model?.division?.reference_code === 'M'
    }
  },
  watch: {
    async clientId (newValue, oldValue) {
      if (oldValue && newValue !== oldValue) {
        this.update('contacts', [])
      }
      this.selectedContactIsDisabled = true
      const response = await ApiRestService.list({ filters: { client_id: this.clientId } }, this.$data.$constants.RESOURCES.RESOURCE_CONTACTS)
      if (response.data.length) {
        this.selectedContactIsDisabled = false
        this.selectedContactKey = `${new Date()}`
      }
    }
  },
  methods: {
    validateForm() {
      return this.$refs['create-job'].validate()
    },
    getContractData() {
      return { contract: this.$refs['contract-input'].getFiles(), removedContract: this.removedContract }
    },
    async handleClientSelect(client) {
      const response = await ApiRestService.get(client.id, this.$data.$constants.RESOURCES.RESOURCE_CLIENTS)
      this.update('type_payment', response.data.type_payment)
    },
  },
}
</script>