<template>
  <BaseModal
    v-model="model"
    title="Nuevo contacto"
    size="xl"
    :loading="loading"
    @confirm-button-click="handleSaveButtonClick"
  >
    <ContactForm
      v-model="contact"
      ref="contact-form"
    />
  </BaseModal>
</template>

<script>
import ApiRestService from '@/api/base-api'
import ContactForm from '@/components/contacts/form/ContactForm.vue'
import BaseModal from '@/components/ui/modal/BaseModal.vue'

export default {
  name: 'ClientCreationModal',
  components: { BaseModal, ContactForm  },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    clientId: {
      type: Number,
      default: true,
    }
  },
  data() {
    return {
      loading: false,
      contact: {},
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    async handleSaveButtonClick() {
      const validForm = await this.$refs['contact-form'].validateForm()

      if (!validForm) {
        return
      }

      this.loading = true
      try {
        const contact = {...this.contact, client_id: this.clientId}
        const response = await ApiRestService.create(contact, this.$data.$constants.RESOURCES.RESOURCE_CONTACTS)
        const newContact = response.data
        this.$toast('Contacto creado con éxito')
        this.$emit('contact-created', newContact)
        this.$emit('input', false)
        this.$refs['contact-form'].clearForm()
      } catch (error) {
        this.$toast.error('Ocurrio un error al crear el contacto. Por favor inténtelo de nuevo mas tarde.')
        console.error(error)
      }

      this.loading = false
    },
  },
}
</script>