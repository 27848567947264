<template>
  <BaseCard
    title="datos del job"
    :loading="loading"
    @save-button-click="handleSaveButtonClick"
  >
    <JobProfileForm
      ref="job-profile-form"
      v-model="job"
      :division-select-enabled="divisionSelectEnabled"
      :offer-select-visible="false"
    />
    <hr class="mx-2">
    <div class="px-2 pt-2 font-weight-bold text-uppercase">
      condiciones economicas del job
    </div>
    <JobEconomicConditionsForm
      ref="job-economic-conditions-form"
      v-model="job"
    />
  </BaseCard>
</template>

<script>
import JobsApi from '@/api/jobs-api'
import BaseCard from '@/components/ui/card/BaseCard.vue'
import JobProfileForm from '@/components/jobs/form/JobProfileForm.vue'
import JobEconomicConditionsForm from '@/components/jobs/form/JobEconomicConditionsForm.vue'
import { mapGetters } from 'vuex'

export default {
  components: { JobEconomicConditionsForm, JobProfileForm, BaseCard },
  data() {
    return {
      job: {},
      loading: false,
    }
  },
  computed: {
    ...mapGetters('auth', {
      user: 'getUser',
    }),
    jobProfileForm() {
      return this.$refs['job-profile-form']
    },
    jobEconomicConditionsForm() {
      return this.$refs['job-economic-conditions-form']
    },
    divisionSelectEnabled() {
      if (!this.user?.roles) {
        return false
      }

      return this.user.roles.some(role => role.name === 'super_admin')
    },
  },
  mounted() {
    this.loadJob()
  },
  methods: {
    async loadJob() {
      try {
        this.loading = true
        const response = await JobsApi.get(this.$route.params.id)
        this.job = response.data
        this.job.contacts = this.job.contacts.length ? this.job.contacts.map(({ id }) => id) : []
      } finally {
        this.loading = false
      }
    },
    async handleSaveButtonClick() {
      const profileValid = await this.jobProfileForm.validateForm()
      if (!profileValid) {
        return
      }

      this.loading = true
      try {
        const { contract, removedContract } = this.jobProfileForm.getContractData()
        const { documentation, removedFiles } = this.jobEconomicConditionsForm.getDocumentationData()
        await Promise.all([
          JobsApi.edit(this.$route.params.id, { ...this.job, contract, documentation }),
          JobsApi.deleteFiles(this.$route.params.id, removedFiles),
          this.removeContract(removedContract),
        ])
        await this.$router.go(-1)
      } finally {
        this.loading = false
      }
    },
    async removeContract(removedContract) {
      if (!removedContract) {
        return
      }

      return JobsApi.deleteContract(this.$route.params.id, removedContract.id, false)
    },
  },
}
</script>

<style scoped>
</style>
