<template>
  <b-row
    align-v="center"
    no-gutters
  >
    <b-col>
      <BaseSelect
        :id="id"
        :disabled="disabled"
        label="fullname"
        :resource="$data.$constants.RESOURCES.RESOURCE_CONTACTS"
        :multiple="true"
        :value="value"
        :http-body="httpBody"
        @input="$emit('input', $event)"
        :reduce="(item) => item.id"
        ref="base-selected"
      />
    </b-col>
    <b-col
      v-show="!disabled"
      cols="auto"
    >
      <b-button
        variant="primary"
        class="text-nowrap rounded-right d-inline-block base-select-add-button"
        @click="contactModalVisible = true"
      >
        <feather-icon
          icon="PlusIcon"
          size="19"
        />
      </b-button>
    </b-col>
    <ContactCreationModal
      v-model="contactModalVisible"
      :clientId="clientId"
      @contact-created="handleSelected"
    />
  </b-row>
</template>

<script>
import BaseSelect from '@/components/ui/select/BaseSelect.vue'
import ContactCreationModal from '@/components/contacts/modal/ContactCreationModal.vue'

export default {
  name: 'ContactSelect',
  components: { 
    ContactCreationModal,
    BaseSelect 
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    value: {
      type: [Array, Object, String, Number],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    httpBody: {
      type: Object,
      default: null,
    },
    clientId: {
      type: Number,
      default: null,
    }
  },
  data() {
    return {
      contactModalVisible: false,
    }
  },
  methods: {
    async handleSelected(event) {
      await this.$refs['base-selected'].loadData()
      this.$emit('input', [...this.value, event.id])
    }
  },
  async mounted() {
    await this.$refs['base-selected'].loadData()
  }
}
</script>

<style scoped>
</style>
